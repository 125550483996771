export const FooterLink2 = [
  {
    title: "Subjects",
    links: [
      { title: "Mathematics", link: "/mathematics" },
      { title: "Science", link: "/science" },
      { title: "Physics", link: "/physics" },
      { title: "Chemistry", link: "/chemistry" },
      { title: "Biology", link: "/biology" },
      { title: "English", link: "/english" },
      { title: "History", link: "/history" },
      { title: "Geography", link: "/geography" },
      { title: "Computer Science", link: "/computer-science" },
      { title: "Environmental Science", link: "/environmental-science" },
      { title: "Social Studies", link: "/social-studies" },
    ],
  },
  {
    title: "Languages",
    links: [
      { title: "English", link: "/english-language" },
      { title: "Hindi", link: "/hindi-language" },
      { title: "Sanskrit", link: "/sanskrit-language" },
    ],
  },
  {
    title: "Career Building",
    links: [
        { title: "Career Guidance", link: "/career-guidance" },
        { title: "Exam Preparation", link: "/exam-preparation" },
        { title: "Study Tips", link: "/study-tips" },
        { title: "Learning Strategies", link: "/learning-strategies" },
        { title: "Full Catalog", link: "/full-catalog" },
        { title: "Beta Content", link: "/beta-content" }
    ],
  }
];
