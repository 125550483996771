export const HomePageExplore = [
    {
      tag: 'Free Resources',
      courses: [
        {
          heading: "Introduction to Mathematics",
          description: "Basic concepts in mathematics for early learners, including arithmetic, geometry, and basic problem-solving skills.",
          level: 'Class 1-3',
          lessonNumber: 5
        },
        {
          heading: "Fundamentals of Science",
          description: "Explore the basics of science with engaging experiments and activities for young students.",
          level: 'Class 1-3',
          lessonNumber: 5
        },
        {
          heading: "Basic English Language Skills",
          description: "Develop fundamental English language skills, including vocabulary, reading comprehension, and simple grammar.",
          level: 'Class 1-3',
          lessonNumber: 5
        },
      ]
    },
    {
      tag: 'New Arrivals',
      courses: [
        {
          heading: "Introduction to Algebra",
          description: "A beginner-friendly course on algebra concepts, including variables, equations, and basic problem-solving.",
          level: 'Class 4-6',
          lessonNumber: 8
        },
        {
          heading: "Exploring Earth Sciences",
          description: "Learn about Earth sciences, including geology, weather, and ecosystems through interactive content.",
          level: 'Class 4-6',
          lessonNumber: 8
        },
        {
          heading: "Intermediate English Grammar",
          description: "Enhance English grammar skills with lessons on sentence structure, punctuation, and advanced vocabulary.",
          level: 'Class 4-6',
          lessonNumber: 8
        },
      ]
    },
    {
      tag: 'Top Courses',
      courses: [
        {
          heading: "Advanced Mathematics",
          description: "Dive into advanced mathematics topics such as geometry, trigonometry, and calculus for high school students.",
          level: 'Class 10-12',
          lessonNumber: 12
        },
        {
          heading: "High School Physics",
          description: "Comprehensive course on high school physics topics including mechanics, electricity, and modern physics.",
          level: 'Class 10-12',
          lessonNumber: 12
        },
        {
          heading: "Advanced English Literature",
          description: "Explore classic and modern literature, including analysis of texts, themes, and critical essays.",
          level: 'Class 10-12',
          lessonNumber: 12
        },
      ]
    },
    {
      tag: 'Skill Development',
      courses: [
        {
          heading: "Critical Thinking and Problem Solving",
          description: "Develop essential critical thinking and problem-solving skills through interactive exercises and case studies.",
          level: 'Class 7-12',
          lessonNumber: 10
        },
        {
          heading: "Effective Study Techniques",
          description: "Learn proven study techniques and strategies to improve learning efficiency and academic performance.",
          level: 'Class 7-12',
          lessonNumber: 10
        },
        {
          heading: "Public Speaking and Communication",
          description: "Enhance public speaking and communication skills with practical exercises and presentation tips.",
          level: 'Class 7-12',
          lessonNumber: 10
        },
      ]
    },
    {
      tag: 'Career Exploration',
      courses: [
        {
          heading: "Introduction to Career Paths",
          description: "Explore various career paths and professional opportunities with insights and guidance for future planning.",
          level: 'Class 10-12',
          lessonNumber: 8
        },
        {
          heading: "Career Skills for Success",
          description: "Acquire essential career skills including resume writing, interview techniques, and job search strategies.",
          level: 'Class 10-12',
          lessonNumber: 8
        },
        {
          heading: "Industry Insights and Trends",
          description: "Gain insights into current industry trends and future career opportunities across different sectors.",
          level: 'Class 10-12',
          lessonNumber: 8
        },
      ]
    },
  ];
  